














































































































































import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import ReportHeader from '@/components/inventory/ReportHeader.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import {
  ApiSvcInventoryCounts,
  ApiSvcInventoryLot,
  ApiSvcInventoryView,
  InventoryApi,
} from '../../../../generated/api-svc';

interface InventoryLot extends ApiSvcInventoryLot {
  timestampLocal: string;
}

@Component({
  components: {
    UiCheckbox,
    CreateInventoryView,
    UiDatePicker,
    UiButton,
    WalletListNew,
    UiDataTable,
    UiTruncateText,
    ReportHeader,
  },
})
export default class InventoryViewLots extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public lots: ApiSvcInventoryLot[] = [];

  public showEmptyLots = false;

  public asOf = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public nextPageToken?: string = '';

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });

  public counts: ApiSvcInventoryCounts | null = null;
  public groupByInventory = false;

  public onGroupByInventoryChange(val: boolean) {
    if (val) {
      this.loadData();
    }
  }

  public get showInventory() {
    return (
      this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-group-mapping' ||
      this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-per-wallet'
    );
  }

  public get headers() {
    if (this.view?.impair) {
      return [
        {
          id: 'acquired',
          label: 'Acquired',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'lotId',
          label: 'Lot ID',
          defaultVisibility: true,
          defaultWidth: '10rem',
          sortable: true,
        },
        {
          id: 'inventory',
          label: 'Inventory',
          defaultVisibility: this.groupByInventory && this.showInventory,
          sortable: true,
          filterable: true,
        },
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
          sortable: true,
          filterable: true,
        },
        {
          id: 'unitsAcquired',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Acquired',
          sortable: true,
        },
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis',
          defaultVisibility: true,
          groupLabel: 'Acquired',
          sortable: true,
        },
        {
          id: 'impairment',
          label: 'Impairment',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'unitsDisposed',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Disposed',
          sortable: true,
        },
        {
          id: 'costBasisDisposed',
          label: 'Carrying Value',
          defaultVisibility: true,
          groupLabel: 'Disposed',
          sortable: true,
        },
        {
          id: 'qty',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Current',
          sortable: true,
        },
        {
          id: 'carryingValue',
          label: 'Carrying Value',
          defaultVisibility: true,
          groupLabel: 'Current',
          sortable: true,
        },
      ];
    } else {
      return [
        {
          id: 'acquired',
          label: 'Acquired',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'lotId',
          label: 'Lot ID',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'inventory',
          label: 'Inventory',
          defaultVisibility: this.groupByInventory && this.showInventory,
          sortable: true,
          filterable: true,
        },
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
          sortable: true,
          filterable: true,
        },
        {
          id: 'unitsAcquired',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Acquired',
          sortable: true,
        },
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis',
          defaultVisibility: true,
          groupLabel: 'Acquired',
          sortable: true,
        },
        {
          id: 'unitsDisposed',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Disposed',
          sortable: true,
        },
        {
          id: 'costBasisDisposed',
          label: 'Cost Basis',
          defaultVisibility: true,
          groupLabel: 'Disposed',
          sortable: true,
        },
        {
          id: 'qty',
          label: 'Qty',
          defaultVisibility: true,
          groupLabel: 'Current',
          sortable: true,
        },
        {
          id: 'costBasis',
          label: 'Cost Basis',
          defaultVisibility: true,
          groupLabel: 'Current',
          sortable: true,
        },
        // {
        //   id: 'fairMarketValue',
        //   label: 'Fair Market Value',
        //   defaultVisibility: true,
        // },
        // {
        //   id: 'unrealizedGainLoss',
        //   label: 'Unrealized Gain Loss',
        //   defaultVisibility: true,
        // },
      ];
    }
  }

  public submittedAsOf = '';

  public get isLoading() {
    return this.isLotsLoading || this.isUniqueColumnsLoading || this.isCountsLoading;
  }

  public isLotsLoading = false;
  public isCountsLoading = false;
  public isUniqueColumnsLoading = false;
  public loadingNextPage = false;
  public csvLoading = false;

  public uniqueColumnValues: { [id: string]: Array<string | number> } = {};

  public filters: any = {};
  public sort: any = {};

  public onFilter(filters: any) {
    this.filters = filters;
    this.loadLots();
  }

  public onSort(sort: any) {
    this.sort = sort;
    this.loadLots();
  }

  async loadCount() {
    if (this.view && this.view.id) {
      this.isCountsLoading = true;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const promise = svc.getViewCounts(this.orgId, this.view.id, this.submittedAsOf, { withCredentials: true });
      const resp = await promise;
      if (resp.status === 200) {
        this.counts = resp.data.counts;
      }
      this.isCountsLoading = false;
    }
  }

  public viewActions(options: { status: string; asOf: string }) {
    this.$emit('viewActions', options);
  }

  async loadUniqueColumns() {
    if (this.view && this.view.id) {
      this.isUniqueColumnsLoading = true;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const promise = Promise.all([
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'asset', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'inventory', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
      ]);

      const [assetResp, inventoryResp] = await promise;

      if (assetResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'asset', assetResp.data.values as Array<string | number>);
      }
      if (inventoryResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'inventory', inventoryResp.data.values as Array<string | number>);
      }

      this.isUniqueColumnsLoading = false;
    }
  }

  async loadLots(getNextPage?: boolean) {
    if (this.view && this.view.id) {
      if (getNextPage) {
        this.loadingNextPage = true;
      } else {
        this.isLotsLoading = true;
      }

      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const promise = svc.getViewLots(
          this.orgId,
          this.view.id,
          this.submittedAsOf,
          this.showEmptyLots,
          getNextPage === true ? this.nextPageToken : undefined,
          undefined,
          undefined,
          this.filters.asset ?? undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.sort.id ?? undefined,
          this.sort.asc ? 'ASC' : 'DESC',
          false,
          this.filters.inventory ?? undefined,
          this.groupByInventory,
          {
            withCredentials: true,
          }
        );
        const resp = await promise;

        if (resp.status === 200) {
          if (!getNextPage) {
            this.lots = [];
          }
          for (const lot of resp.data.lots as InventoryLot[]) {
            lot.timestampLocal = this.toLocalDateTime(lot.timestampSEC);
            this.lots.push(lot);
          }

          this.nextPageToken = resp.data.nextPageToken ?? '';
        }
      } finally {
        if (getNextPage) {
          this.loadingNextPage = false;
        } else {
          this.isLotsLoading = false;
        }
      }
    }
  }

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;

  downloadFile = downloadAuthorizedFile;

  public async downloadCSV() {
    if (!this.view?.id) return;
    this.csvLoading = true;

    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await svc.getViewLots(
        this.orgId,
        this.view.id,
        this.submittedAsOf,
        this.showEmptyLots,
        undefined,
        undefined,
        undefined,
        this.filters.asset ?? undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.sort.id ?? undefined,
        this.sort.asc ? 'ASC' : 'DESC',
        true,
        this.filters.inventory ?? undefined,
        this.groupByInventory,
        {
          withCredentials: true,
        }
      );

      if (resp.status === 200) {
        const exportIds = resp.data.exportIds;
        const downloadUrlPromises = [] as any[];
        exportIds?.forEach((x: any) => {
          downloadUrlPromises.push(
            axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
              withCredentials: true,
            })
          );
        });
        const downloadUrls = await Promise.all(downloadUrlPromises);
        downloadUrls.forEach((x: any) => {
          this.downloadFile(x.data);
        });
      }
    } finally {
      this.csvLoading = false;
    }
  }

  public loadData() {
    this.submittedAsOf = this.asOf;
    this.loadLots();
    this.loadUniqueColumns();
    this.loadCount();
  }

  async mounted() {
    this.loadData();
  }

  async getNextPage() {
    if (this.nextPageToken) {
      await this.loadLots(true);
    }
  }
}
