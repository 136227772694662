var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"actionsContainer",staticClass:"tw-p-4"},[_c('div',{staticClass:"tw-flex tw-items-end tw-gap-4"},[_c('ui-date-picker',{attrs:{"label":"As Of"},model:{value:(_vm.asOf),callback:function ($$v) {_vm.asOf=$$v},expression:"asOf"}}),_c('div',{staticClass:"tw-flex tw-h-9 tw-flex-grow"},[_c('ui-button',{attrs:{"disabled":_vm.isLoading,"color":"primary"},nativeOn:{"click":function($event){return _vm.loadData.apply(null, arguments)}}},[_vm._v(" Submit ")])],1),_c('div',[_c('ui-button',{attrs:{"loading":_vm.csvLoading,"color":"white"},nativeOn:{"click":function($event){return _vm.downloadCSV.apply(null, arguments)}}},[_vm._v(" Download Actions as of "+_vm._s(_vm.submittedAsOf)+" ")])],1)],1),_c('div',{staticClass:"tw-rounded-lg tw-bg-white tw-shadow tw-mt-3"},[_c('Transition',{attrs:{"name":"expand"}},[(_vm.counts && !_vm.isCountsLoading)?_c('report-header',{attrs:{"counts":_vm.counts,"asOf":_vm.submittedAsOf},on:{"viewActions":_vm.viewActions}}):_vm._e()],1),_c('ui-data-table',{ref:"actionsTable",attrs:{"headers":_vm.headers,"items":_vm.actions,"isLoading":!!_vm.isLoading,"no-data-message":"There are no actions to display.","resizeable-columns":"","fixed-table":"","hideable-columns":"","uniqueColumnValues":_vm.uniqueColumnValues,"data-testId":"inventory-actions-table"},on:{"sort":_vm.onSort,"filter":_vm.onFilter},scopedSlots:_vm._u([{key:"td-timestampSEC",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-timestamp")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.toLocalDateTime(action.timestampSEC)}})],1)])]}},{key:"td-action",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-action")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(action.action)+" ")])])]}},{key:"td-status",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-status")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.status}})],1)])]}},{key:"td-inventory",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-inventory")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.inventory}})],1)])]}},{key:"td-wallet",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-wallet")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.wallet}})],1)])]}},{key:"td-toInventory",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-to-inventory")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.toInventory}})],1)])]}},{key:"td-fromInventory",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-from-inventory")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.fromInventory}})],1)])]}},{key:"td-transferLotId",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-transfer-lot-id")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.transferLotId}})],1)])]}},{key:"td-originalLotId",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-original-lot-id")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.originalLotId}})],1)])]}},{key:"td-txnId",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-txn-id")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.txnId}})],1)])]}},{key:"td-lotId",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-lot-id")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.lotId}})],1)])]}},{key:"td-asset",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-asset")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.asset}})],1)])]}},{key:"td-assetUnitAdj",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-asset-unit-adj")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.assetUnitAdj, { decimalPlaces: 6 })}})],1)])]}},{key:"td-assetBalance",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-asset-balance")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.assetBalance, { decimalPlaces: 3 })}})],1)])]}},{key:"td-txnExchangeRate",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-txn-exchange-rate")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.txnExchangeRate, { decimalPlaces: 6 })}})],1)])]}},{key:"td-carryingValue",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-carrying-value")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.carryingValue, { decimalPlaces: 6 })}})],1)])]}},{key:"td-impairmentExpense",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-impairment-expense")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.impairmentExpense, { decimalPlaces: 6 })}})],1)])]}},{key:"td-proceeds",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-proceeds")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.proceeds}})],1)])]}},{key:"td-shortTermGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-short-term-gain-loss")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.shortTermGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-longTermGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-long-term-gain-loss")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.longTermGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-undatedGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-undated-gain-loss")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.undatedGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costBasisAquired",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-cost-basis-acquired")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costBasisAquired, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costBasisRelieved",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-cost-basis-relieved")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costBasisRelieved, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costAverageRate",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-cost-avg-rate")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costAverageRate, { decimalPlaces: 6 })}})],1)])]}},{key:"td-isTrade",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-is-trade")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(action.isTrade)+" ")])])]}},{key:"td-lineError",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-line-error")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":action.lineError}})],1)])]}}])})],1),_c('div',{staticClass:"tw-content-center tw-p-4 tw-block"},[(_vm.nextPageToken)?_c('ui-button',{attrs:{"loading":_vm.loadingNextPage,"data-testId":"inventory-actions-btn-next-page"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.getNextPage.apply(null, arguments)}}},[_vm._v("Get Next Page")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }