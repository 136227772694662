









































































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import numberUtils from '@/utils/numberUtils';
interface Counts {
  actions: number;
  acquisitions: number;
  dispositions: number;
  uncategorized: number;
  errored: number;
  negativeBalanceError: number;
  lineErrors: number;
}
@Component({
  components: {},
})
export default class Paywall extends BaseVue {
  @Prop({ required: true })
  public readonly counts!: Counts;

  @Prop({ required: true })
  public readonly asOf!: string;

  public numFormat = numberUtils.format;

  public viewActions(status: string, asOf: string) {
    this.$emit('viewActions', { status, asOf });
  }
}
