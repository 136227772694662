



















import { Component, Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiButton from './UiButton.vue';
import UiSelect from './UiSelect.vue';

interface Trade {
  tradeId: string;
  asset: string;
  quantity: number;
  wallet: string;
  selectionId: string;
}

interface Selection {
  selectionId: string;
  asset: string;
  quantity: number;
  wallet: string;
}

@Component({
  components: {
    UiButton,
    UiSelect,
  },
})
export default class MatchTradeUi extends BaseVue {
  @Prop({ required: true })
  public readonly trades!: Trade[];

  @Prop({ required: true })
  public readonly selections!: Selection[];

  public selectedTrade: string | null = null;
  public selectedSelection: string | null = null;
  public isLoading = false;

  get tradeOptions() {
    return this.trades.map((trade) => ({
      value: trade.tradeId,
      label: `Trade ${trade.tradeId} - ${trade.asset} (${trade.quantity}) - ${trade.wallet}`,
    }));
  }

  get selectionOptions() {
    return this.selections.map((selection) => ({
      value: selection.selectionId,
      label: `Selection ${selection.selectionId} - ${selection.asset} (${selection.quantity}) - ${selection.wallet}`,
    }));
  }

  public confirmMatch() {
    if (this.selectedTrade && this.selectedSelection) {
      this.isLoading = true;

      this.$emit('confirmMatch', {
        tradeId: this.selectedTrade,
        selectionId: this.selectedSelection,
      });
      this.selectedTrade = null;
      this.selectedSelection = null;
      this.isLoading = false;
    }
  }

  mounted() {
    console.log('Trades passed to Match Trade UI:', this.trades);
    console.log('Selections passed to Match Trade UI:', this.selections);
  }
}
