var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"lotsContainer",staticClass:"tw-p-4"},[_c('div',{staticClass:"tw-flex tw-items-end tw-gap-4"},[_c('ui-date-picker',{attrs:{"label":"As Of","data-testId":"inventory-lots-input-date"},model:{value:(_vm.asOf),callback:function ($$v) {_vm.asOf=$$v},expression:"asOf"}}),_c('div',{staticClass:"tw-py-2"},[_c('ui-checkbox',{attrs:{"label":"Show Empty Lots"},model:{value:(_vm.showEmptyLots),callback:function ($$v) {_vm.showEmptyLots=$$v},expression:"showEmptyLots"}})],1),(_vm.showInventory)?_c('div',{staticClass:"tw-py-2"},[_c('ui-checkbox',{attrs:{"label":"Include Inventory"},on:{"input":_vm.onGroupByInventoryChange},model:{value:(_vm.groupByInventory),callback:function ($$v) {_vm.groupByInventory=$$v},expression:"groupByInventory"}})],1):_vm._e(),_c('div',{staticClass:"tw-flex tw-h-9 tw-flex-grow"},[_c('ui-button',{attrs:{"disabled":_vm.isLoading,"color":"primary"},nativeOn:{"click":function($event){return _vm.loadData.apply(null, arguments)}}},[_vm._v(" Submit ")])],1),_c('div',[_c('ui-button',{attrs:{"ading":_vm.csvLoading,"color":"white"},nativeOn:{"click":function($event){return _vm.downloadCSV.apply(null, arguments)}}},[_vm._v(" Download Lots as of "+_vm._s(_vm.submittedAsOf)+" ")])],1)],1),_c('div',{staticClass:"tw-w-full tw-rounded-t-md tw-bg-white tw-shadow tw-mt-3"},[_c('Transition',{attrs:{"name":"expand"}},[(_vm.counts && !_vm.isCountsLoading)?_c('report-header',{attrs:{"counts":_vm.counts,"asOf":_vm.asOf},on:{"viewActions":_vm.viewActions}}):_vm._e()],1),_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lots,"isLoading":!!_vm.isLoading,"resizeable-columns":"","no-data-message":"There are no lots to display.","fixed-table":"","show-group-headers":"","uniqueColumnValues":_vm.uniqueColumnValues,"data-testId":"inventory-lots-table"},on:{"sort":_vm.onSort,"filter":_vm.onFilter},scopedSlots:_vm._u([{key:"td-acquired",fn:function(ref){
var lot = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-acquired")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"value":_vm.toLocalDateTime(lot.timestampSEC),"position":"right"}})],1)])]}},{key:"td-lotId",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-lot-id")}},[_c('ui-truncate-text',{attrs:{"copyable":true,"value":lot.lotId,"position":"right"}})],1)]}},{key:"td-asset",fn:function(ref){
var lot = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-asset")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(lot.asset)+" ")])])]}},{key:"td-inventory",fn:function(ref){
var action = ref.item;
return [_c('td',{attrs:{"data-testId":("inventory-actions-tr-" + (action.eventId) + "-inventory")}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.inventory}})],1)])]}},{key:"td-unitsAcquired",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-qty-acquired")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.unitsAcquired, { decimalPlaces: 6 }))+" ")])]}},{key:"td-unitsDisposed",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-qty-disposed")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.unitsDisposed, { decimalPlaces: 6, accountingNegative: true }))+" ")])]}},{key:"td-qty",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-qty-current")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.qty, { decimalPlaces: 6 }))+" ")])]}},{key:"td-costBasisAcquired",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-cost-basis-aquired")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.costBasisAcquired, { decimalPlaces: 3 }))+" ")])]}},{key:"td-costBasisDisposed",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-cost-basis-disposed")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.costBasisRelieved, { decimalPlaces: 3, accountingNegative: true, flipSign: true }))+" ")])]}},{key:"td-impairment",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-impairment")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.impairmentExpense, { decimalPlaces: 3, accountingNegative: true, flipSign: true }))+" ")])]}},{key:"td-costBasis",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-cost-basis-current")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.costBasis, { decimalPlaces: 3 }))+" ")])]}},{key:"td-carryingValue",fn:function(ref){
var lot = ref.item;
return [_c('td',{staticClass:"tw-text-neutral-400 tw-my-4 tw-pl-3",attrs:{"data-testId":("inventory-lots-tr-" + (lot.lotId) + "-td-carrying-value-current")}},[_vm._v(" "+_vm._s(_vm.numFormat(lot.carryingValue, { decimalPlaces: 3 }))+" ")])]}}])})],1),_c('div',{staticClass:"tw-content-center tw-p-4 tw-block"},[(_vm.nextPageToken)?_c('ui-button',{attrs:{"loading":_vm.loadingNextPage},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.getNextPage.apply(null, arguments)}}},[_vm._v("Get Next Page")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }