// import moment from "moment";
import moment from 'moment-timezone';

export function toLocalDateTime(timestamp: number): string {
  const unixTime = moment.unix(timestamp);
  return unixTime.local().format('lll');
}

/**
 * Returns the UTC time in unix (epoch seconds) of 00:00:00 the day after
 * the supplied date. Meant to be used with a less than (exclusive) comparison
 * for reporting generation. In other words, use for (..., <supplied date>)
 * type reports
 */
export function getReportEndingDateSec(timezone: string, localDate: string): number {
  const localReportEnd = moment.tz(localDate + ' 00:00:00', timezone).add(1, 'day');
  const balanceTimeUTC = localReportEnd.utc().unix();
  return balanceTimeUTC;
}

/**
 * Generate an exclusive (>) starting date for a report
 * Give 2018-12-01, timezone Los Angeles, will return unix for 2018-12-01
 * 7:59:59 (GMT) or 1543651199
 * @param timezone Org Timezone
 * @param localDate The local simple  date string, in the form '2018-12-01'
 */
export function getReportStartingDateSec(timezone: string, localDate: string): number {
  const localReportStart = moment.tz(localDate + ' 23:59:59', timezone).subtract(1, 'day');
  const balanceTimeUTC = localReportStart.utc().unix();
  return balanceTimeUTC;
}
