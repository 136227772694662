var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"specIdContainer",staticClass:"tw-p-4"},[_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4"},[_c('div',{staticClass:"tw-bg-white tw-shadow tw-rounded-md tw-p-4 tw-h-full tw-border tw-border-gray-400"},[_c('h2',{staticClass:"tw-px-3 tw-py-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-900"},[_vm._v(" Contemporaneous Selection ")]),_c('trading-ui',{attrs:{"lots":_vm.openLots,"selectedLot":_vm.selectedLot},on:{"confirmSelection":_vm.handleSelectionConfirmation}})],1),_c('div',{staticClass:"tw-bg-white tw-shadow tw-rounded-md tw-p-4 tw-h-full tw-border tw-border-gray-400"},[_c('h2',{staticClass:"tw-px-3 tw-py-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-900"},[_vm._v("Post Trade Matching")]),_c('match-trade-ui',{attrs:{"trades":_vm.openTrades,"selections":_vm.selections},on:{"confirmMatch":_vm.handleMatchConfirmation}})],1)]),_c('div',{staticClass:"tw-flex tw-mt-4"},[_c('div',{staticClass:"tw-w-1/3 tw-bg-white tw-shadow tw-rounded-md tw-mr-4 tw-border tw-border-gray-400"},[_c('h3',{staticClass:"tw-px-3 tw-py-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-900"},[_vm._v("Open Lots")]),_c('ui-data-table',{attrs:{"headers":_vm.lotHeaders,"items":_vm.openLots,"no-data-message":"No open lots.","data-testId":"open-lots-table"},on:{"row-clicked":_vm.handleLotSelection},scopedSlots:_vm._u([{key:"td-lotId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.lotId))])]}},{key:"td-asset",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.asset))])]}},{key:"td-quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.quantity))])]}},{key:"td-wallet",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.wallet))])]}},{key:"td-price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(" $"+_vm._s(_vm.formatCurrency(item.price))+" ")])]}},{key:"td-currentPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(" $"+_vm._s(_vm.formatCurrency(item.currentPrice))+" ")])]}},{key:"td-taxImpact",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm",class:{ 'tw-text-green-500': item.taxImpact > 0, 'tw-text-red-500': item.taxImpact < 0 }},[_vm._v(" $"+_vm._s(_vm.formatCurrency(item.taxImpact))+" ")])]}}])})],1),_c('div',{staticClass:"tw-w-1/3 tw-bg-white tw-shadow tw-rounded-md tw-mr-4 tw-border tw-border-gray-400"},[_c('h3',{staticClass:"tw-px-3 tw-py-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-900"},[_vm._v("Selections")]),_c('ui-data-table',{attrs:{"headers":_vm.selectionHeaders,"items":_vm.selections,"no-data-message":"No selections.","data-testId":"selections-table"},scopedSlots:_vm._u([{key:"td-timestamp",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.timestamp))])]}},{key:"td-selectionId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.selectionId))])]}},{key:"td-lotId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.lotId))])]}},{key:"td-asset",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.asset))])]}},{key:"td-quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.quantity))])]}},{key:"td-wallet",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.wallet))])]}}])})],1),_c('div',{staticClass:"tw-w-1/3 tw-bg-white tw-shadow tw-rounded-md tw-border tw-border-gray-400"},[_c('h3',{staticClass:"tw-px-3 tw-py-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-900"},[_vm._v(" Unmatched Transactions ")]),_c('ui-data-table',{attrs:{"headers":_vm.tradeHeaders,"items":_vm.openTrades,"no-data-message":"No unmatched transactions.","data-testId":"open-trades-table"},scopedSlots:_vm._u([{key:"td-timestamp",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.timestamp))])]}},{key:"td-tradeId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.tradeId))])]}},{key:"td-asset",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.asset))])]}},{key:"td-quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.quantity))])]}},{key:"td-wallet",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.wallet))])]}},{key:"td-selectionId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700"},[_vm._v(_vm._s(item.selectionId))])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }