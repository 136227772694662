<template>
  <span
    v-bind:class="{
      loss: amount < 0 && gainLossColoring,
      gain: amount > 0 && gainLossColoring,
    }"
    >{{ accountingFormat(amount) }}</span
  >
</template>

<script>
import * as math from 'mathjs';

export default {
  props: {
    amount: [String, Number],
    gainLossColoring: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    accountingFormat(value) {
      if (value === undefined || value === '' || value === null) {
        return '';
      }

      const bn = math.bignumber(value);

      if (value < 0) {
        return this.sym() + '(' + this.formatNumber(bn.abs()) + ')';
      } else {
        return this.sym() + this.formatNumber(bn);
      }
    },
    formatNumber(value, decimalPlaces) {
      const d = decimalPlaces || 2;
      if (value !== undefined && value !== '') {
        const vn = math.bignumber(value);
        const n = vn.toDecimalPlaces(d);
        const ret = n.toNumber().toLocaleString(undefined, {
          minimumFractionDigits: d,
          maximumFractionDigits: d,
        });
        return ret;
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.loss {
  color: #d00600;
}
.gain {
  color: #00a500;
}
</style>
