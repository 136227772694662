































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import FirstTimeView from '@/components/inventory/FirstTime/FirstTimeView.vue';
import InventoryViewDetails from '@/components/inventory/InventoryViewDetails.vue';
import Paywall from '@/components/inventory/Paywall.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

import { baConfig } from '../../../config';
import { ApiSvcInventoryViewDTO, InventoryApi } from '../../../generated/api-svc';
@Component({
  components: {
    InventoryViewDetails,
    CreateInventoryView,
    UiButton,
    WalletListNew,
    Paywall,
    FirstTimeView,
    UiSelect2,
  },
})
export default class WalletsNew extends BaseVue {
  public views: ApiSvcInventoryViewDTO[] = [];
  public isCreatingView = false;
  public isLoading = false;
  public selectedViewId: string | null = null;
  public isPaywallVisible = false;
  public showFeedback = true;

  public onCreateView() {
    if (this.views.length === 0 || this.checkFeatureFlag('inventory-create-multiple-views')) {
      this.isCreatingView = true;
    } else {
      this.isPaywallVisible = true;
    }
  }

  public onBackToList() {
    this.isCreatingView = false;
  }

  public getTabClass(view: string) {
    if (view === this.selectedViewId) {
      return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm tw-truncate ';
    } else {
      return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm tw-truncate ';
    }
  }

  public async loadInventoryViews() {
    this.isLoading = true;
    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const orgId = this.$store.state.currentOrg.id;
      const views = await svc.getViews(orgId, undefined, { withCredentials: true });
      if (views.status === 200) {
        this.$set(this, 'views', views.data.items);
        this.selectedViewId = this.views[0].id;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    await this.loadInventoryViews();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    await this.loadInventoryViews();
    this.onBackToList();
  }

  @Watch('selectedViewId')
  async loadInventory(value: string) {
    // do stuff
  }
}
