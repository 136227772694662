







































































































































import { v4 as uuid } from 'uuid';
import { Component } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import MatchTradeUi from '@/components/ui/UiMatching.vue';
import TradingUi from '@/components/ui/UiTrading.vue';

interface Lot {
  lotId: number;
  asset: string;
  quantity: number;
  wallet: string;
  price: number;
  currentPrice: number;
  taxImpact: number;
}

interface Selection {
  timestamp: string;
  selectionId: string;
  lotId: number;
  asset: string;
  quantity: number;
  wallet: string;
}

interface Trade {
  timestamp: string;
  tradeId: string;
  lotId: number;
  asset: string;
  quantity: number;
  wallet: string;
  selectionId: string;
}

@Component({
  components: {
    UiDataTable,
    TradingUi,
    MatchTradeUi,
  },
})
export default class InventoryViewSpecId extends BaseVue {
  public openLots: Lot[] = [
    { lotId: 1, asset: 'BTC', quantity: 10, wallet: 'Wallet 1', price: 50000, currentPrice: 60000, taxImpact: 10000 },
    { lotId: 2, asset: 'ETH', quantity: 20, wallet: 'Wallet 2', price: 4500, currentPrice: 4000, taxImpact: -500 },
    { lotId: 3, asset: 'BTC', quantity: 5, wallet: 'Wallet 1', price: 70000, currentPrice: 60000, taxImpact: -10000 },
    { lotId: 4, asset: 'ETH', quantity: 15, wallet: 'Wallet 2', price: 1000, currentPrice: 4000, taxImpact: 3000 },
  ];

  public selections: Selection[] = [];
  public openTrades: Trade[] = [];

  public selectedLot: Lot | null = null;

  public lotHeaders = [
    { id: 'lotId', label: 'Lot ID' },
    { id: 'asset', label: 'Asset' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'wallet', label: 'Wallet' },
    { id: 'price', label: 'Lot Price' },
    { id: 'currentPrice', label: 'Current Price' },
    { id: 'taxImpact', label: 'Estimated Tax Impact' },
  ];

  public selectionHeaders = [
    { id: 'timestamp', label: 'Timestamp' },
    { id: 'selectionId', label: 'Selection ID' },
    { id: 'lotId', label: 'Lot ID' },
    { id: 'asset', label: 'Asset' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'wallet', label: 'Wallet' },
  ];

  public tradeHeaders = [
    { id: 'timestamp', label: 'Timestamp' },
    { id: 'tradeId', label: 'Txn ID' },
    { id: 'asset', label: 'Asset' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'wallet', label: 'Wallet' },
    { id: 'selectionId', label: 'Selection ID' },
  ];

  handleLotSelection({ item }: { item: Lot }) {
    this.selectedLot = item;
  }

  handleSelectionConfirmation(selection: Selection) {
    const lot = this.openLots.find(
      (l) => l.lotId === selection.lotId && l.asset === selection.asset && l.wallet === selection.wallet
    );
    if (lot) {
      lot.quantity -= selection.quantity;
      if (lot.quantity <= 0) {
        this.openLots = this.openLots.filter((l) => l.lotId !== selection.lotId);
      }
      this.$set(this, 'openLots', [...this.openLots]);
    }
    this.selections.push(selection);
    this.$set(this, 'selections', [...this.selections]);

    // TODO: just for the demo :) remove later
    setTimeout(() => {
      const timestamp = new Date().toISOString();
      const trade: Trade = {
        timestamp: timestamp,
        tradeId: uuid(),
        lotId: selection.lotId,
        asset: selection.asset,
        quantity: selection.quantity,
        wallet: selection.wallet,
        selectionId: '',
      };
      this.openTrades.push(trade);
      this.$set(this, 'openTrades', [...this.openTrades]);
    }, 5000);
  }

  handleMatchConfirmation(match: { tradeId: string; selectionId: string }) {
    const trade = this.openTrades.find((t) => t.tradeId === match.tradeId);
    if (trade) {
      trade.selectionId = match.selectionId;
      this.$set(this, 'openTrades', [...this.openTrades]);
    }
  }

  mounted() {
    console.log('Open Lots:', this.openLots);
  }
}
