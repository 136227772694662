


























import { v4 as uuid } from 'uuid';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiButton from './UiButton.vue';
import UiSelect from './UiSelect.vue';
import UiTextEdit from './UiTextEdit.vue';

interface Lot {
  lotId: number;
  asset: string;
  quantity: number;
  wallet: string;
}

@Component({
  components: {
    UiButton,
    UiSelect,
    UiTextEdit,
  },
})
export default class TradingUi extends BaseVue {
  @Prop({ required: true })
  public readonly lots!: Lot[];

  @Prop({ required: false })
  public readonly selectedLot!: Lot | null;

  public selectionId: string | null = null;
  public selectedAsset: string | null = null;
  public tradeQuantity: number | null = null;
  public selectedWallet: string | null = null;
  public selectedLotId: number | null = null;
  public isLoading = false;

  get assetOptions() {
    const uniqueAssets = Array.from(new Set(this.lots.map((lot) => lot.asset)));
    return uniqueAssets.map((asset) => ({
      value: asset,
      label: asset,
    }));
  }

  get lotOptions() {
    return this.lots
      .filter((lot) => lot.asset === this.selectedAsset && lot.wallet === this.selectedWallet)
      .map((lot) => ({
        value: lot.lotId,
        label: lot.lotId,
      }));
  }

  get walletOptions() {
    const uniqueWallets = Array.from(new Set(this.lots.map((lot) => lot.wallet)));
    return uniqueWallets.map((wallet) => ({
      value: wallet,
      label: wallet,
    }));
  }

  @Watch('selectedLot')
  onSelectedLotChanged(newLot: Lot | null) {
    if (newLot) {
      this.selectedAsset = newLot.asset;
      this.tradeQuantity = newLot.quantity;
      this.selectedWallet = newLot.wallet;
      this.selectedLotId = newLot.lotId;
    }
  }

  public confirmSelection() {
    if (this.selectedAsset && this.tradeQuantity !== null && this.selectedWallet && this.selectedLotId !== null) {
      this.isLoading = true;
      const matchingLot = this.lots.find(
        (lot) =>
          lot.asset === this.selectedAsset &&
          lot.wallet === this.selectedWallet &&
          lot.lotId === this.selectedLotId &&
          lot.quantity >= this.tradeQuantity!
      );
      const timestamp = new Date().toISOString();
      const selectionId = uuid();
      if (matchingLot) {
        this.$emit('confirmSelection', {
          timestamp: timestamp,
          selectionId: selectionId,
          lotId: matchingLot.lotId,
          asset: this.selectedAsset,
          quantity: this.tradeQuantity,
          wallet: this.selectedWallet,
        });
      }
      this.selectionId = null;
      this.selectedAsset = null;
      this.tradeQuantity = null;
      this.selectedWallet = null;
      this.selectedLotId = null;
      this.isLoading = false;
    }
  }

  mounted() {
    console.log('Lots passed to Trading UI:', this.lots);
  }
}
