

































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import { SpendingStrategy, StrategyPriority } from '@/components/org/types';

@Component({})
export default class TaxStrategyPicker extends BaseVue {
  strategyRanges: StrategyPriority[] = [];
  selected = [];
  isLoading = 0;
  isSaving = false;
  spendingStrategies = [
    {
      text: 'Short Term Gain',
      value: 'ShortTermGain',
    },
    {
      text: 'Short Term Loss',
      value: 'ShortTermLoss',
    },
    {
      text: 'Long Term Gain',
      value: 'LongTermGain',
    },
    {
      text: 'Long Term Loss',
      value: 'LongTermLoss',
    },
  ];

  selectionPreferences = [
    {
      text: 'Minimize',
      value: 'Minimize',
    },
    {
      text: 'Maximize',
      value: 'Maximize',
    },
  ];

  headers = [
    {
      text: 'Priority',
      align: 'left',
      value: 'priority',
    },
    {
      text: 'Spending Strategy',
      align: 'left',
      value: 'spendingStrategy',
    },
    {
      text: 'Preference',
      align: 'left',
      value: 'selectionPreference',
    },
  ];

  mounted() {
    this.strategyRanges = new Array(4)
      .fill(0)
      .map((_) => Object.assign({ spendingStrategy: null, selectionPreference: null }));
  }

  availableSpendingStrategies(index: number) {
    const selectedStrategies: SpendingStrategy[] = [];

    this.strategyRanges.forEach((el: StrategyPriority, index_) => {
      if (el.spendingStrategy && index !== index_) {
        selectedStrategies.push(el.spendingStrategy);
      }
    });
    return this.spendingStrategies.filter((el) => !selectedStrategies.includes(el.value as SpendingStrategy));
  }

  onSpendingStrategyChange() {
    this.$emit('change', this.strategyRanges);
  }
}
