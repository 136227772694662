
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import FirstTimeListItem from './FirstTimeListItem.vue';

@Component({
  components: { FirstTimeListItem },
})
export default class FirstTimeView extends Vue {
  public toggle(val: boolean, index: number) {
    if (val) {
      const items = Object.values(this.$refs);
      (items as FirstTimeListItem[])?.forEach((item: any, i: number) => {
        if (i !== index) {
          item.expand = false;
        }
      });
    }
  }
}
