




















































































































































































































































































import axios from 'axios';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import ReportHeader from '@/components/inventory/ReportHeader.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import {
  ApiSvcInventoryAction,
  ApiSvcInventoryCounts,
  ApiSvcInventoryView,
  ApiSvcTaxStrategyType,
  InventoryApi,
} from '../../../../generated/api-svc';
@Component({
  components: {
    UiCheckbox,
    CreateInventoryView,
    UiDatePicker,
    UiButton,
    WalletListNew,
    UiDataTable,
    UiTruncateText,
    ReportHeader,
  },
})
export default class InventoryViewActions extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  @Prop({ default: null })
  public readonly defaultFilter!: { [id: string]: Array<string | number> };

  @Prop({ default: '' })
  public readonly defaultAsOf!: string;

  public actions: ApiSvcInventoryAction[] = [];

  public asOf = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public nextPageToken?: string = '';

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });

  public submittedAsOf = '';

  public get isLoading() {
    return this.isLotsLoading || this.isUniqueColumnsLoading || this.isCountsLoading;
  }

  public get headers() {
    const headers = [
      {
        id: 'timestampSEC',
        label: 'Timestamp',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'action',
        label: 'Action',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      },
    ];
    if (this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-per-wallet') {
      headers.push({
        id: 'wallet',
        label: 'Wallet',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      });
      headers.push({
        id: 'inventory',
        label: 'Inventory',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      });
      headers.push({
        id: 'toInventory',
        label: 'To Inventory',
        defaultVisibility: true,
        sortable: true,
      });
      headers.push({
        id: 'fromInventory',
        label: 'From Inventory',
        defaultVisibility: true,
        sortable: true,
      });
      headers.push({
        id: 'originalLotId',
        label: 'Original Lot ID',
        defaultVisibility: false,
        sortable: true,
      });
      headers.push({
        id: 'transferLotId',
        label: 'Transfer Lot ID',
        defaultVisibility: false,
        sortable: true,
      });
    } else if (this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-group-mapping') {
      headers.push({
        id: 'wallet',
        label: 'Wallet',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      });
      headers.push({
        id: 'inventory',
        label: 'Inventory',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      });
    }
    headers.push(
      ...[
        {
          id: 'txnId',
          label: 'Txn ID',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'lotId',
          label: 'Lot ID',
          defaultVisibility: false,
          sortable: true,
        },
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
          sortable: true,
          filterable: true,
        },
        {
          id: 'assetUnitAdj',
          label: 'Asset Unit Adj',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'assetBalance',
          label: 'Asset Balance',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'txnExchangeRate',
          label: 'Txn Exchange Rate',
          defaultVisibility: true,
          sortable: true,
        },
        {
          id: 'carryingValue',
          label: 'Carrying Value',
          defaultVisibility: true,
          sortable: true,
        },
      ]
    );
    if (this.view?.impair) {
      headers.push({
        id: 'impairmentExpense',
        label: 'Impairment Expense',
        defaultVisibility: true,
        sortable: true,
      });
    }
    headers.push(
      ...[
        {
          id: 'proceeds',
          label: 'Proceeds',
          defaultVisibility: false,
          sortable: true,
        },
      ]
    );
    if (!this.isAverageCost) {
      headers.push(
        ...[
          {
            id: 'shortTermGainLoss',
            label: 'Short Term Gain Loss',
            defaultVisibility: false,
            sortable: true,
          },
          {
            id: 'longTermGainLoss',
            label: 'Long Term Gain Loss',
            defaultVisibility: false,
            sortable: true,
          },
        ]
      );
    }
    if (this.isAverageCost) {
      headers.push({
        id: 'undatedGainLoss',
        label: 'Undated Gain Loss',
        defaultVisibility: false,
        sortable: true,
      });
    }
    headers.push(
      ...[
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis Acquired',
          defaultVisibility: false,
          sortable: true,
        },
        {
          id: 'costBasisRelieved',
          label: 'Cost Basis Relieved',
          defaultVisibility: true,
          sortable: true,
        },
      ]
    );
    if (this.isAverageCost) {
      headers.push({
        id: 'costAverageRate',
        label: 'Cost Average Rate',
        defaultVisibility: false,
        sortable: true,
      });
    }
    headers.push(
      ...[
        {
          id: 'isTrade',
          label: 'Is Trade',
          defaultVisibility: false,
          sortable: true,
        },
        {
          id: 'lineError',
          label: 'Line Error',
          defaultVisibility: false,
          sortable: true, // line error only true if there is an error
        },
      ]
    );

    return headers;
  }

  public get isAverageCost() {
    return this.view?.inventoryPickingStrategy.type === ApiSvcTaxStrategyType.NUMBER_4;
  }

  public sort: any = {};
  public filters: any = {};
  public uniqueColumnValues: { [id: string]: Array<string | number> } = {};
  public counts: ApiSvcInventoryCounts | null = null;

  public setFilter(options: { [id: string]: Array<string | number> }) {
    Vue.set(this.$refs.actionsTable as Vue, 'selectedFilters', options);
    this.onFilter(options);
  }

  public viewActions(options: { status: string; asOf: string }) {
    this.setFilter({ status: [options.status] });
  }

  public onFilter(filters: any) {
    this.filters = filters;
    this.loadLots();
  }

  public onSort(sort: any) {
    this.sort = sort;
    this.loadLots();
  }

  public isLotsLoading = false;
  public isCountsLoading = false;
  public isUniqueColumnsLoading = false;
  public loadingNextPage = false;

  async loadUniqueColumns() {
    if (this.view && this.view.id) {
      this.isUniqueColumnsLoading = true;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const promise = Promise.all([
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'asset', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'status', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'inventory', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
        svc.getActionColumnUniqueValues(this.orgId, this.view.id, 'wallet', false, this.submittedAsOf, undefined, {
          withCredentials: true,
        }),
      ]);

      const [assetResp, statusResp, inventoryResp, walletResp] = await promise;

      if (assetResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'asset', assetResp.data.values as Array<string | number>);
      }
      if (statusResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'status', statusResp.data.values as Array<string | number>);
      }
      if (inventoryResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'inventory', inventoryResp.data.values as Array<string | number>);
      }
      if (walletResp.status === 200) {
        this.$set(this.uniqueColumnValues, 'wallet', walletResp.data.values as Array<string | number>);
      }
      this.isUniqueColumnsLoading = false;
    }
  }

  async loadLots(getNextPage?: boolean) {
    if (this.view && this.view.id) {
      if (getNextPage) {
        this.loadingNextPage = true;
      } else {
        this.isLotsLoading = true;
      }

      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const promise = svc.getViewActions(
          this.orgId,
          this.view.id,
          this.submittedAsOf,
          getNextPage === true ? this.nextPageToken : undefined,
          undefined,
          undefined,
          undefined,
          this.filters.status ?? undefined,
          undefined,
          undefined,
          undefined,
          this.filters.asset ?? undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.sort.id ?? undefined,
          this.sort.asc ? 'ASC' : 'DESC',
          false,
          this.filters.inventory ?? undefined,
          this.filters.wallet ?? undefined,
          {
            withCredentials: true,
          }
        );

        const resp = await promise;

        if (resp.status === 200) {
          if (getNextPage) {
            this.actions.push(...resp.data.actions);
          } else {
            this.actions = resp.data.actions;
          }

          this.nextPageToken = resp.data.nextPageToken ?? '';
        }
      } finally {
        if (getNextPage) {
          this.loadingNextPage = false;
        } else {
          this.isLotsLoading = false;
        }
      }
    }
  }

  async loadCount() {
    this.isCountsLoading = true;
    if (this.view && this.view.id) {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const promise = svc.getViewCounts(this.orgId, this.view.id, this.submittedAsOf, { withCredentials: true });
      const resp = await promise;
      if (resp.status === 200) {
        this.counts = resp.data.counts;
      }
      this.isCountsLoading = false;
    }
  }

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
  public csvLoading = false;

  downloadFile = (link: string) => downloadAuthorizedFile(link);

  public async downloadCSV() {
    if (!this.view?.id) return;
    this.csvLoading = true;

    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await svc.getViewActions(
        this.orgId,
        this.view.id,
        this.submittedAsOf,
        undefined,
        undefined,
        undefined,
        undefined,
        this.filters.action ?? undefined,
        undefined,
        undefined,
        undefined,
        this.filters.asset ?? undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.sort.id ?? undefined,
        this.sort.asc ? 'ASC' : 'DESC',
        true,
        this.filters.inventory ?? undefined,
        this.filters.wallet ?? undefined,
        {
          withCredentials: true,
        }
      );

      if (resp.status === 200) {
        const exportIds = resp.data.exportIds;
        const downloadUrlPromises = [] as any[];
        exportIds?.forEach((x: any) => {
          downloadUrlPromises.push(
            axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
              withCredentials: true,
            })
          );
        });
        const downloadUrls = await Promise.all(downloadUrlPromises);
        downloadUrls.forEach((x: any) => {
          this.downloadFile(x.data);
        });
      }
    } finally {
      this.csvLoading = false;
    }
  }

  public loadData() {
    this.submittedAsOf = this.asOf;
    this.loadLots();
    this.loadUniqueColumns();
    this.loadCount();
  }

  async mounted() {
    if (this.defaultFilter) {
      this.setFilter(this.defaultFilter);
    }
    if (this.defaultAsOf) {
      this.asOf = this.defaultAsOf;
    }
    this.loadData();
  }

  async getNextPage() {
    if (this.nextPageToken) {
      await this.loadLots(true);
    }
  }
}
