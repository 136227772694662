















import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import { ApiSvcInventoryView, InventoryApi } from '../../../../generated/api-svc';
@Component({
  components: {
    UiCheckbox,
    CreateInventoryView,
    UiDatePicker,
    UiButton,
    WalletListNew,
    UiDataTable,
  },
})
export default class InventoryActionsReport extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public startDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');
  public endDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });

  public csvLoading = false;

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;

  private downloadFile = downloadAuthorizedFile;

  async downloadReport() {
    if (this.view && this.view.id) {
      this.csvLoading = true;

      const frames = (this.$refs.inventoryViewContainer as Element)?.querySelectorAll('iframe.temp-iframe');
      if (frames?.length) {
        (this.$refs.inventoryViewContainer as Element)?.removeChild(frames[0]);
      }

      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const p1 = svc.getInventoryEnhancedGainLoss(
          this.orgId,
          this.view.id,
          this.startDate,
          this.endDate,
          true,
          undefined,
          { withCredentials: true }
        );

        const resp = await p1;

        if (resp.status === 200) {
          const exportIds = resp.data.exportIds;
          const downloadUrlPromises = [] as any[];
          exportIds?.forEach((x: any) => {
            downloadUrlPromises.push(
              axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
                withCredentials: true,
              })
            );
          });
          const downloadUrls = await Promise.all(downloadUrlPromises);
          downloadUrls.forEach((x: any) => {
            this.downloadFile(x.data);
          });
        }
      } finally {
        this.csvLoading = false;
      }
    }
  }
}
