<template>
  <v-layout row wrap>
    <iframe id="downloadFrame" style="display: none"></iframe>
    <div class="tw-mt-4 tw-border tw-border-gray-300 tw-w-full">
      <ui-data-table
        :headers="headers"
        :items="reports"
        :isLoading="isLoading"
        no-data-message="There is no report to display."
      >
        <template #td-action="{ item: report }">
          <td>
            <ui-button color="white" @click.native.stop="downloadFile(report, 'actions')" class="tw-mr-4">
              Download Actions
            </ui-button>
            <ui-button color="white" @click.native.stop="downloadFile(report, 'results')"> Download Results </ui-button>
          </td>
        </template>
      </ui-data-table>
    </div>
    <v-flex xs12 v-if="reportData">
      <v-layout row wrap>
        <v-flex xs2 offset-xs10>
          <v-btn v-if="reportData.actionsDownloadUrl" :href="reportData.actionsDownloadUrl">
            Download Actions CSV
          </v-btn>
          <v-btn v-if="reportData.largeFileDownloadUrl" :href="reportData.largeFileDownloadUrl">
            Download Large CSV
          </v-btn>
          <download-csv class="btn btn-default" name="gainLoss.csv" :data="gainLossData" :labels="labels" v-else>
            <v-btn>Download CSV</v-btn>
          </download-csv>
        </v-flex>
        <v-flex xs6 pa-1>
          <v-card v-bind:class="{ incomplete: !reportData.matchSuccessful }">
            <h4>Gain Loss</h4>
            <p v-if="!reportData.matchSuccessful">
              Gain loss data may be incomplete, see full report for uncategorized transactions
            </p>
            <div>
              Unrealized:
              <acct-num :amount="reportData.totalUnrealizedGainLoss" />
              <br />
            </div>
            <div v-if="reportData.totalRealizedShortTermGainLoss !== undefined">
              Realized Short Term:
              <acct-num :amount="reportData.totalRealizedShortTermGainLoss" />
              <br />
            </div>
            <div v-if="reportData.totalRealizedLongTermGainLoss !== undefined">
              Realized Long Term:
              <acct-num :amount="reportData.totalRealizedLongTermGainLoss" />
              <br />
            </div>
            <div v-if="reportData.totalRealizedUndatedGainLoss !== undefined">
              Realized Undated:
              <acct-num :amount="reportData.totalRealizedUndatedGainLoss" />
              <br />
            </div>
            <div v-if="reportData.totalExternalUnrealizedGainLoss !== undefined">
              External Unrealized:
              <acct-num :amount="reportData.totalExternalUnrealizedGainLoss" />
              <br />
            </div>
            <div v-if="reportData.totalAdjustments !== undefined">
              Wrapping Related Adjustments:
              <acct-num :amount="reportData.totalAdjustments" />
              <br />
            </div>
            <div v-if="reportData.totalAdjustments !== undefined">
              Impairment Expense:
              <acct-num :amount="reportData.totalImpairmentExpense * -1" />
              <br />
            </div>
          </v-card>
        </v-flex>

        <v-flex xs6 pa-1>
          <v-card>
            <h4>Exchange Rates</h4>
            <v-layout row wrap>
              <v-flex xs6 v-for="(er, index) in exchangeRates" v-bind:key="`rate-${index}`">
                <v-layout row wrap>
                  <v-flex xs4> {{ er.displayName }}: </v-flex>
                  <v-flex xs8 v-if="!er.editing">
                    {{ er.overrideRate }}
                    <v-icon @click="er.editing = true" size="small">edit</v-icon>
                  </v-flex>
                  <v-flex xs8 v-else>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-text-field v-model="er.overrideRate" clear-icon="delete" solo></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-icon @click="er.editing = false">checkmark</v-icon>
                        <v-icon @click="cancelEdit(er)">delete</v-icon>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card>
            <v-layout justify-space-between>
              <v-card-title class="title">Summary Lines</v-card-title>
              <download-csv name="summary.csv" :data="summaryLineData">
                <v-btn>Download Summary</v-btn>
              </download-csv>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs1></v-flex>
              <v-flex xs1>Ending Balance</v-flex>
              <v-flex xs2>Cumulative Cost</v-flex>
              <v-flex xs2 v-if="impair">Carrying Cost</v-flex>
              <v-flex xs2>Ending Gain / Loss</v-flex>
              <v-flex xs2>Ending Unrealized</v-flex>
              <v-flex xs2 v-if="impair">Impairement Expense</v-flex>
            </v-layout>
            <v-layout row wrap v-for="(sl, si) in reportData.summaryLines" v-bind:key="`sl-${si}`">
              <v-flex xs1>{{ sl.value.ticker }}</v-flex>
              <v-flex xs1>{{ sl.runningBalance ? formatNumber(sl.runningBalance.amount, 2, 5) : '' }}</v-flex>
              <v-flex xs2>{{ sl.cumulativeCost ? sym() + formatNumber(sl.cumulativeCost) : '' }}</v-flex>
              <v-flex xs2 v-if="impair">
                {{ sl.impairedCumulativeCost ? formatNumber(sl.impairedCumulativeCost, 2, 5) : '' }}
              </v-flex>
              <v-flex xs2>
                <div v-if="sl.runningGainLoss">
                  <div v-if="sl.runningGainLoss.shortTermGainLoss">
                    ST:
                    <acct-num :amount="sl.runningGainLoss.shortTermGainLoss" />
                  </div>
                  <div v-if="sl.runningGainLoss.longTermGainLoss">
                    LT:
                    <acct-num :amount="sl.runningGainLoss.longTermGainLoss" />
                  </div>
                  <div v-if="sl.runningGainLoss.undatedGainLoss">
                    UD:
                    <acct-num :amount="sl.runningGainLoss.undatedGainLoss" />
                  </div>
                </div>
              </v-flex>
              <v-flex xs2> UR <acct-num :amount="sl.runningUnrealizedGainLoss" /> </v-flex>
              <v-flex xs2 v-if="impair">
                <acct-num :amount="sl.impairmentExpense * -1" />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import * as math from 'mathjs';

import { baConfig } from '../../../config';
import { ReportingApi } from '../../../generated/api-svc';
import { ApiSvcReportRunConfigTypes } from '../../../generated/api-svc/api';
import { toLocalDateTime } from '../../utils/dateUtils';
import AcctNum from '../accounting/AcctNum';
import UiButton from '../ui/UiButton';
import UiDataTable from '../ui/UiDataTable';

export default {
  props: [],
  apollo: {
    $client: 'rptApolloClient',
  },
  data: () => ({
    reports: [],
    startDateShow: false,
    endDateShow: false,
    startDate: '',
    endDate: '',
    reportData: null,
    taxStrategy: null,
    capitalizeFees: true,
    isLoading: false,
    error: null,
    hasError: false,
    showDetails: false,
    impair: false,
    ignoreNFTs: false,
    taxMethods: [
      {
        id: 'FIFO',
        name: 'First In, First Out (FIFO)',
      },
      {
        id: 'LIFO',
        name: 'Last In, First Out (LIFO)',
      },
      {
        id: 'CostAverage',
        name: 'Cost Averaging',
      },
      {
        id: 'SpecificIdentification',
        name: 'Specific Identification (pre-configured rules)',
      },
    ],
    exchangeRates: [],
    headers: [
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        selector: (report) => report.status,
      },
      {
        id: 'time',
        label: 'Time',
        defaultVisibility: true,
        selector: (report) => toLocalDateTime(report.createdSEC),
      },
      {
        id: 'id',
        label: 'Id',
        defaultVisibility: true,
        selector: (report) => report.id,
      },
      {
        id: 'createdBy',
        label: 'Created By',
        defaultVisibility: true,
        selector: (report) => report.createdBy.name,
      },
      {
        id: 'action',
        label: 'Actions',
        defaultVisibility: true,
      },
    ],
  }),
  computed: {
    allowImpairment() {
      return this.checkFeatureFlag('impairment', this.$store.getters.features);
    },
    items() {
      return this.reportData.lines;
    },
    showExternal() {
      const f = this.reportData.lines.find((m) => m.externalUnrealizedGainLoss);
      return !!f;
    },
    showAdjustments() {
      const f = this.reportData.lines.find((m) => m.adjustments);
      return !!f;
    },
    labels() {
      const labels = {
        txnId: this.$tc('_txnId'),
        txnTime: this.$tc('_txnTime'),
        coin: this.$tc('_coin'),
        amount: this.$tc('_amount'),
        costBasis: this.$tc('_costBasis'),
        costBasisRelieved: this.$tc('_costBasisRelieved'),
        cumulativeCost: this.$tc('_cumulativeCost'),
        proceeds: this.$tc('_proceeds'),
        exchangeRate: this.$tc('_exchangeRate'),
        unrealizedGains: this.$tc('_unrealizedGains'),
        udGainLoss: this.$tc('_udGainLoss'),
        stGainLoss: this.$tc('_stGainLoss'),
        ltGainLoss: this.$tc('_ltGainLoss'),
        runningBalance: this.$tc('_runningBalance'),
        udRunningGainLoss: this.$tc('_udRunningGainLoss'),
        stRunningGainLoss: this.$tc('_stRunningGainLoss'),
        ltRunningGainLoss: this.$tc('_ltRunningGainLoss'),
        runningUnrealized: this.$tc('_runningUnrealized'),
      };
      return labels;
    },
    gainLossData() {
      const sym = this.sym();
      const gainLossData = this.reportData.lines.map((txn) => {
        return this.mapGainLoss(txn, sym);
      });
      return gainLossData;
    },
    summaryLineData() {
      const sym = this.sym();
      const gainLossData = this.reportData.summaryLines.map((txn) => {
        return this.mapGainLoss(txn, sym);
      });
      return gainLossData;
    },
    currentTimezone() {
      return this.$store.state.currentOrg.timezone ? this.$store.state.currentOrg.timezone : 'N/A';
    },
    overrideRates() {
      const m = this.exchangeRates.filter((m) => m.rate !== m.overrideRate);
      return m;
    },
  },
  async mounted() {
    this.isLoading = true;
    const apiSvc = new ReportingApi(undefined, baConfig.getFriendlyApiUrl());
    const orgId = this.$store.state.currentOrg.id;
    const resp = await apiSvc.getReports(orgId, ApiSvcReportRunConfigTypes.GainLossReport, undefined, {
      withCredentials: true,
    });
    if (resp.data) {
      this.reports.push(...resp.data.items);
    }

    this.isLoading = false;
  },
  methods: {
    async downloadFile(record, link) {
      const reportingApi = new ReportingApi(undefined, baConfig.getFriendlyApiUrl());
      const reportRecord = await reportingApi.getReport(record.orgId, record.id, true, { withCredentials: true });
      const downloadFrame = document.getElementById('downloadFrame');
      const l = reportRecord.data.links[link];
      if (l) {
        downloadFrame.src = l.href;
      }

      // const record = reportingApi.
    },
    mapGainLoss(txn, sym) {
      const matchDetails = this.formatMatches(txn).join('\r\n');

      const gainLoss = {
        txnId: txn.taxEventId,
        txnTime: this.toOrgLocalTime(txn.timeSEC, true),
        coin: txn.value.ticker,
        amount: this.formatNumber(txn.value.amount, 2, 4),
        costBasis: txn.cost ? sym + this.formatNumber(txn.cost) : '',
        costBasisRelieved: txn.costRelieved ? sym + this.formatNumber(txn.costRelieved) : '',
        cumulativeCost: txn.cumulativeCost ? sym + this.formatNumber(txn.cumulativeCost) : '',
        proceeds: txn.proceeds ? sym + this.formatNumber(txn.proceeds) : '',
        exchangeRate: txn.exchangeRate ? this.accountingFormat(txn.exchangeRate) : '',
        unrealizedGains: txn.unrealizedGainLoss ? this.accountingFormat(txn.unrealizedGainLoss) : '',
        udGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.undatedGainLoss) : '',
        stGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.shortTermGainLoss) : '',
        ltGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.longTermGainLoss) : '',
        runningBalance: txn.runningBalance ? this.formatNumber(txn.runningBalance.amount, 2, 5) : '',
        udRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.undatedGainLoss) : '',
        stRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.shortTermGainLoss) : '',
        ltRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.longTermGainLoss) : '',
        runningUnrealized: txn.runningUnrealizedGainLoss ? this.accountingFormat(txn.runningUnrealizedGainLoss) : '',
        matchDetails,
      };
      return gainLoss;
    },
    cancelEdit(er) {
      er.overrideRatte = null;
      er.editing = false;
    },
    formatMatch(txn, m) {
      let dateTimeSEC;
      let prefix;
      if (txn.taxEventId === m.acquiredTaxEventId) {
        dateTimeSEC = m.disposedDateTimeSEC;
        prefix = 'Disposed on';
      } else if (txn.taxEventId === m.disposedTaxEventId) {
        dateTimeSEC = m.acquiredDateTimeSEC;
        prefix = 'Acquired on';
      } else {
        return 'Problem detecting match';
      }

      return `${prefix} ${this.toOrgLocalTime(dateTimeSEC, true)} : ${m.matchedAmount.ticker} ${this.formatNumber(
        m.matchedAmount.amount,
        2,
        5
      )} : ${this.accountingFormat(m.costRelieved)}`;
    },
    formatMatches(txn) {
      const matchDetails = txn.matches ? txn.matches.map((m) => this.formatMatch(txn, m)) : [];
      return matchDetails;
    },
    accountingFormat(value) {
      if (value === undefined || value === '') {
        return '';
      }

      const bn = math.bignumber(value);

      if (value < 0) {
        return this.sym() + '(' + this.formatNumber(bn.abs()) + ')';
      } else {
        return this.sym() + this.formatNumber(bn);
      }
    },
    formatNumber(value, minDecimalPlaces, maxDecimalPlaces) {
      const minDec = minDecimalPlaces || 2;
      const maxDec = maxDecimalPlaces || 2;
      if (value !== undefined && value !== '') {
        const vn = math.bignumber(value);
        const n = vn.toDecimalPlaces(maxDecimalPlaces);
        const ret = n.toNumber().toLocaleString(undefined, {
          minimumFractionDigits: minDec,
          maximumFractionDigits: maxDec,
        });
        return ret;
      } else {
        return '';
      }
    },
    async runReport() {
      this.error = null;
      this.hasError = false;

      let overrideExchangeRates;
      if (this.overrideRates.length > 0) {
        overrideExchangeRates = this.overrideRates.map((m) => {
          return {
            currencyId: m.currencyId,
            rate: m.overrideRate,
          };
        });
      }

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        startDate: this.startDate,
        endDate: this.endDate,
        strategy: this.taxStrategy,
        config: {
          capitalizeFees: this.capitalizeFees,
        },
        overrideExchangeRates,
        impair: this.impair,
        ignoreNFTs: this.ignoreNFTs,
      };

      this.isLoading = true;
      this.hasError = false;

      // const vars = {
      //   orgId: this.$store.state.currentOrg.id,
      //   startDate: this.startDate,
      //   endDate: this.endDate,
      //   strategy: this.taxStrategy,
      //   config: {
      //     capitalizeFees: this.capitalizeFees,
      //   },
      //   overrideExchangeRates,
      //   impair: this.impair,
      //   ignoreNFTs: this.ignoreNFTs,
      // };

      try {
        const req = {
          config: {
            type: ApiSvcReportRunConfigTypes.GainLossReport,
            orgId: this.$store.state.currentOrg.id,
            strategy: this.taxStrategy,
            startDateTimeSEC: this.startDate,
            endDateTimeSEC: this.endDate,
            taxConfig: {
              capitalizeFees: this.capitalizeFees,
            },
            impair: this.impair,
            ignoreNFTs: this.ignoreNFTs,
            overrideExchangeRates,
          },
        };

        const reportSvc = new ReportingApi(undefined, baConfig.apiUrl);
        const r = await reportSvc.run(this.$store.state.currentOrg.id, req, { withCredentials: true });

        // this.reportData = m.data.taxes.runScenario;
        // if (this.reportData.exchangeRatePointers) {
        //   this.exchangeRates = this.reportData.exchangeRatePointers.map((m) => {
        //     // <span>{{er.ticker}}: {{er.rate}}</span>
        //
        //     this.$set(m, 'overrideRate', m.rate);
        //     this.$set(m, 'editing', false);
        //     return m;
        //   });
        // }

        this.isLoading = false;
      } catch (e) {
        console.log('Error: ', e);
        this.isLoading = false;
        this.hasError = true;
      }
    },
    toggleDetails(txn) {
      this.$set(txn.item, 'showDetails', !txn.item.showDetails);
      // txn.item.showDetails = !txn.item.showDetails;
    },
  },
  components: {
    AcctNum,
    UiDataTable,
    UiButton,
  },
};
</script>

<style scoped>
.header-row {
  text-align: right;
}

.header-col-left {
  text-align: left;
}

.input-row {
  text-align: right;
}

.incomplete {
  color: #ff6b00 !important;
}

.incomplete-row {
  color: #ff6b00 !important;
  text-align: right;
}

.total-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: right;
  height: 25px;
}

.loss {
  color: #d00600;
}

.gain {
  color: #00a500;
}

.exchange-rate {
  vertical-align: top;
}

div.div-grid {
  display: grid;
}
</style>
