var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4"},[_c('div',{staticClass:"tw-rounded-lg tw-bg-white tw-shadow tw-mt-3"},[_c('ui-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.updates,"isLoading":!!_vm.isLoading,"show-refresh":true,"no-data-message":"There are no updates to display.","resizeable-columns":"","fixed-table":""},on:{"refresh":_vm.loadUpdates},scopedSlots:_vm._u([{key:"td-id",fn:function(ref){
var update = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":update.id}})],1)])]}},{key:"td-runId",fn:function(ref){
var update = ref.item;
return [_c('td',[(update.gainLossRunId)?_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":update.gainLossRunId}})],1):_vm._e()])]}},{key:"td-started-by",fn:function(ref){
var update = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":update.updateRequestedBy !== undefined ? update.updateRequestedBy.name : ''}})],1)])]}},{key:"td-started",fn:function(ref){
var update = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.toLocalDateTime(update.updateRequestedSEC)}})],1)])]}},{key:"td-completed",fn:function(ref){
var update = ref.item;
return [_c('td',{staticClass:"tw-flex tw-flex-col"},[(update.updateCompletedSEC)?_c('div',[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.toLocalDateTime(update.updateCompletedSEC)}})],1):_c('div',[_vm._v("--")])])]}},{key:"td-status",fn:function(ref){
var update = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.parseStatus(update)}})],1)])]}},{key:"td-actions",fn:function(ref){
var update = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-justify-between"},[(_vm.shouldShowCancelButton(update))?_c('ui-button',{staticClass:"tw-ml-2",attrs:{"size":"small","variant":"danger"},nativeOn:{"click":function($event){return _vm.cancelViewUpdate(update.id)}}},[_vm._v(" Cancel ")]):_vm._e()],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }