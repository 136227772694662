


























































































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import GainLossReports from '@/components/taxes/GainLossReports.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import { baConfig } from '../../../config';
import {
  ApiSvcRunReportRequest,
  ApiSvcRunScenarioParams,
  ApiSvcTaxStrategyTypeDTO,
  ReportingApi,
} from '../../../generated/api-svc';

@Component({
  components: {
    GainLossReports,
    UiCheckbox,
    UiButton,
    UiTextEdit,
    UiLoading,
  },
})
export default class ScenarioRunner extends BaseVue {
  public isLoading = false;
  public taxStrategy: ApiSvcTaxStrategyTypeDTO = ApiSvcTaxStrategyTypeDTO.Fifo;
  public startDate: string | null = null;
  public endDate: string | null = null;
  public ignoreNFTs = false;
  public impair = false;
  public capitalizeFees = true;
  public taxMethods = [
    {
      id: 'FIFO',
      name: 'First In, First Out (FIFO)',
    },
    {
      id: 'LIFO',
      name: 'Last In, First Out (LIFO)',
    },
    {
      id: 'CostAverage',
      name: 'Cost Averaging',
    },
    {
      id: 'SpecificIdentification',
      name: 'Specific Identification (pre-configured rules)',
    },
  ];

  get allowImpairment() {
    return this.checkFeatureFlag('impairment', this.$store.getters.features);
  }

  public async runReport() {
    const rptSvc = new ReportingApi(undefined, baConfig.getFriendlyApiUrl());
    const req: ApiSvcRunReportRequest = {
      gainLoss: {
        orgId: this.orgId,
        startDate: this.startDate ?? undefined,
        endDate: this.endDate ?? undefined,
        config: {
          capitalizeFees: this.capitalizeFees,
        },
        impair: this.impair,
        ignoreNFTs: this.ignoreNFTs,
        strategy: this.taxStrategy,
      },
    };

    const resp = await rptSvc.run(this.orgId, req, { withCredentials: true });
  }
}
