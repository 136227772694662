





















import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
@Component({
  components: {
    UiButton,
    UiModal,
  },
})
/*
 * PaywallView
 * this is a modal that is displayed when a user tries to create a new inventory view
 * but they have not upgraded their account
 * We could make this into a global paywall component if we decided to paywall other features
 */
export default class Paywall extends BaseVue {
  @Prop({ required: true })
  public readonly open!: boolean;

  public close() {
    this.$emit('close');
  }
}
