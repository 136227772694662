




































































































































































import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import {
  ApiSvcInventoryView,
  ApiSvcRippleInventoryCostBasisRollForwardLine,
  InventoryApi,
} from '../../../../generated/api-svc';

export interface DataTableLine extends ApiSvcRippleInventoryCostBasisRollForwardLine {
  isTotal?: boolean;
}

@Component({
  components: {
    UiButton,
    UiDatePicker,
    UiDataTable,
  },
})
export default class ExpandedCostBasisReport extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public lines: DataTableLine[] = [];

  public startDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');
  public endDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public csvLoading = false;
  public isLoading = false;

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });
  private baseUrl = baConfig.rptApiUrl ?? baConfig.apiUrl;
  private downloadFile = downloadAuthorizedFile;
  public reportElapsedTime? = '';

  // NOTE: If needed convert to a getter fn for dynamic headers, see CostBasisRollForwardReport
  public headers = [
    {
      id: 'original_inventory',
      label: 'Original Inventory',
      defaultVisibility: true,
    },
    {
      id: 'original_wallet',
      label: 'Original Wallet',
      defaultVisibility: true,
    },
    {
      id: 'asset',
      label: 'Asset',
      defaultVisibility: true,
    },
    {
      id: 'lotID',
      label: 'Lot ID',
      defaultVisibility: true,
    },
    {
      id: 'original_department',
      label: 'Original Department',
      defaultVisibility: true,
    },
    {
      id: 'units_beginning',
      label: 'Units (beg)',
      defaultVisibility: true,
    },
    {
      id: 'carryingvalue_beginning',
      label: 'Carrying Value (beg)',
      defaultVisibility: true,
    },
    {
      id: 'units_acquired',
      label: 'Units (acq)',
      defaultVisibility: true,
    },
    {
      id: 'units_disposed',
      label: 'Units (disp)',
      defaultVisibility: true,
    },
    {
      id: 'units_ending',
      label: 'Units (end)',
      defaultVisibility: true,
    },
    {
      id: 'costbasis_acquired',
      label: 'Cost Basis (acq)',
      defaultVisibility: true,
    },
    {
      id: 'costbasis_relieved',
      label: 'Cost Basis Relieved',
      defaultVisibility: true,
    },
    {
      id: 'impairExpense',
      label: 'Impair Expense',
      defaultVisibility: true,
    },
    {
      id: 'impairmentReversal',
      label: 'Impair Reversal',
      defaultVisibility: true,
    },
    {
      id: 'revaluationAdjustmentUpward',
      label: 'Revaluation Adjustment Upward',
      defaultVisibility: true,
    },
    {
      id: 'revaluationAdjustmentDownward',
      label: 'Revaluation Adjustment Downward',
      defaultVisibility: true,
    },
    {
      id: 'carryingvalue_ending',
      label: 'Carrying Value (end)',
      defaultVisibility: true,
    },
  ];

  async runReport() {
    if (!this.view?.id) return;

    this.isLoading = true;
    this.reportElapsedTime = undefined;
    const reportStartTime = Date.now();

    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await svc.getRippleInventoryCostBasisRollForward(
        this.orgId,
        this.view.id,
        this.startDate,
        this.endDate,
        false,
        { withCredentials: true }
      );

      if (resp.status !== 200) return;

      this.lines = resp.data.lines;

      const _myNum = (x: any) => {
        if (typeof x === 'string') {
          return isNaN(Number(x)) ? 0 : Number(x);
        } else if (typeof x === 'undefined') {
          return 0;
        } else {
          return isNaN(x) ? 0 : x;
        }
      };

      const _totalKeys = this.headers.map((x) => x.id);
      const _totals = this.lines.reduce((acc: any, next: any) => {
        acc.original_inventory = 'TOTAL';

        for (const key of _totalKeys) {
          if (['asset', 'original_inventory', 'original_wallet', 'original_department', 'lotID'].includes(key))
            continue;

          // first check if existing value is a string, if so convert to number
          acc[key] = _myNum(acc[key]);
          // add accumulated value to next value
          acc[key] += _myNum(next[key]);
        }

        return acc as DataTableLine;
      }, {} as DataTableLine);

      // add totals to the end of the lines
      this.lines.push({ ..._totals, isTotal: true });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.reportElapsedTime = this.getElapsedTime(reportStartTime);
    }
  }

  async downloadReport() {
    if (!this.view?.id) return;

    this.csvLoading = true;

    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await svc.getRippleInventoryCostBasisRollForward(
        this.orgId,
        this.view.id,
        this.startDate,
        this.endDate,
        true,
        { withCredentials: true }
      );

      if (resp.status === 200) {
        const exportIds = resp.data.exportIds;
        const downloadUrlPromises = [] as any[];
        exportIds?.forEach((x: any) => {
          downloadUrlPromises.push(
            axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
              withCredentials: true,
            })
          );
        });
        const downloadUrls = await Promise.all(downloadUrlPromises);
        downloadUrls.forEach((x: any) => {
          this.downloadFile(x.data);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.csvLoading = false;
    }
  }
}
