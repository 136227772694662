





































































































































































































































































import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import {
  ApiSvcGainLossSummary,
  ApiSvcInventoryCostBasisRollForwardLine,
  ApiSvcInventoryView,
  ApiSvcTaxStrategyType,
  InventoryApi,
} from '../../../../generated/api-svc';

// used to add isTotal property to the line
export interface DataTableLine extends ApiSvcInventoryCostBasisRollForwardLine {
  isTotal?: boolean;
}

@Component({
  components: {
    UiCheckbox,
    CreateInventoryView,
    UiDatePicker,
    UiButton,
    WalletListNew,
    UiDataTable,
    UiTruncateText,
  },
})
export default class CostBasisRollForwardReport extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public lines: DataTableLine[] = [];
  public summary: ApiSvcGainLossSummary[] = [];

  public startDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');
  public endDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });
  public unrealizedGainLoss = 0;
  public includeFmv = false;

  public reportElapsedTime? = '';

  public getGainLossClass(gl: number) {
    if (gl > 0) {
      return 'tw-text-green-500';
    } else if (gl < 0) {
      return 'tw-text-red-500';
    } else {
      return '';
    }
  }

  public get isAverageCost() {
    return this.view?.inventoryPickingStrategy.type === ApiSvcTaxStrategyType.NUMBER_4;
  }

  public groupByInventory = false;
  public groupByLotsWallets = false;
  public groupByLots = false;
  public groupByWallets = false;

  public get showInventory() {
    return (
      this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-group-mapping' ||
      this.view?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-per-wallet'
    );
  }

  public get headers() {
    const headers = [];
    if (this.groupByInventory && !this.groupByWallets) {
      headers.push({
        id: 'inventory',
        label: 'Inventory',
        defaultVisibility: true,
      });
    } else if (this.groupByInventory && this.groupByWallets && !this.groupByLots) {
      headers.push({
        id: 'inventory',
        label: 'Inventory',
        defaultVisibility: true,
      });

      headers.push({
        id: 'wallet',
        label: 'Wallet',
        defaultVisibility: true,
      });
    } else if (this.groupByInventory && this.groupByWallets && this.groupByLots) {
      headers.push({
        id: 'original_inventory',
        label: 'Inventory',
        defaultVisibility: true,
      });

      headers.push({
        id: 'lotID',
        label: 'Lot ID',
        defaultVisibility: true,
        defaultWidth: '10rem',
      });

      headers.push({
        id: 'original_wallet',
        label: 'Wallet',
        defaultVisibility: true,
      });
    }

    headers.push(
      ...[
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
        },
        {
          id: 'starting_qty_value',
          label: 'Qty (beg)',
          defaultVisibility: true,
        },
        {
          id: 'starting_fiat_value',
          label: this.view?.impair ? 'Carrying Value (beg)' : 'Cost Basis (beg)',
          defaultVisibility: true,
        },
        {
          id: 'qty_increases',
          label: 'Qty (acq)',
          defaultVisibility: true,
        },
        {
          id: 'fiat_increases',
          label: 'Cost Basis (acq)',
          defaultVisibility: true,
        },
        {
          id: 'qty_decreases',
          label: 'Qty (disp)',
          defaultVisibility: true,
        },
        {
          id: 'fiat_decreases',
          label: 'Proceeds From Disposal',
          defaultVisibility: true,
          groupLabel: this.view?.impair ? 'Carrying Value Disposed' : 'Cost Basis Disposed',
        },
        {
          id: 'st_gain_loss',
          label: 'ST Gain Loss',
          defaultVisibility: true,
          groupLabel: this.view?.impair ? 'Carrying Value Disposed' : 'Cost Basis Disposed',
        },
      ]
    );
    if (this.isAverageCost) {
      headers.push({
        id: 'un_gain_loss',
        label: 'Undated Gain Loss',
        defaultVisibility: true,
        groupLabel: this.view?.impair ? 'Carrying Value Disposed' : 'Cost Basis Disposed',
      });
    }

    headers.push({
      id: 'lt_gain_loss',
      label: 'LT Gain Loss',
      defaultVisibility: true,
      groupLabel: this.view?.impair ? 'Carrying Value Disposed' : 'Cost Basis Disposed',
    });

    // if (this.view?.impair) {
    //   headers.push({
    //     id: 'un_gain_loss',
    //     label: 'Undated Gain Loss',
    //     defaultVisibility: true,
    //     groupLabel: this.view?.impair ? 'Carrying Value Disposed' : 'Cost Basis Disposed',
    //   });
    // }

    if (this.view?.impair) {
      headers.push({
        id: 'impairExpense_fiat_decreases',
        label: 'Impairment Expense',
        defaultVisibility: true,
      });
      headers.push({
        id: 'impairExpenseReversal_fiat_increases',
        label: 'Impairment Reversal',
        defaultVisibility: true,
      });
    }

    headers.push({
      id: 'ending_qty_value',
      label: this.view?.impair ? 'Qty (end)' : 'Qty (end)',
      defaultVisibility: true,
    });

    headers.push({
      id: 'ending_fiat_value',
      label: this.view?.impair ? 'Carrying Value (end)' : 'Cost Basis (end)',
      defaultVisibility: true,
    });

    if (this.includeFmv) {
      headers.push(
        {
          id: 'current_price',
          label: this.view?.impair ? 'Current Price' : 'Current Price',
          defaultVisibility: true,
        },
        {
          id: 'fair_market_value',
          label: this.view?.impair ? 'Fair Market Value' : 'Fair Market Value',
          defaultVisibility: true,
        },
        {
          id: 'unrealized_gain_loss',
          label: this.view?.impair ? 'Unrealized Gain/Loss' : 'Unrealized Gain/Loss',
          defaultVisibility: true,
        }
      );
    }

    return headers;
  }

  public isLoading = false;

  async runReport() {
    if (this.view && this.view.id) {
      this.isLoading = true;
      this.reportElapsedTime = undefined;
      const reportStartTime = Date.now();

      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const p1 = svc.getAdvancedCostBasisRollForward(
          this.orgId,
          this.view.id,
          this.startDate,
          this.endDate,
          this.groupByInventory,
          this.groupByWallets && this.groupByInventory,
          this.groupByLots && this.groupByInventory && this.groupByWallets,
          false,
          this.includeFmv,
          {
            withCredentials: true,
          }
        );

        const p2 = svc.getsGainLossSummary(this.orgId, this.view.id, this.startDate, this.endDate, undefined, {
          withCredentials: true,
        });

        const [resp, summaryResp] = await Promise.all([p1, p2]);

        if (resp.status === 200 && summaryResp.status === 200) {
          this.lines = resp.data.lines;

          const _myNum = (x: any) => {
            if (typeof x === 'string') {
              return isNaN(Number(x)) ? 0 : Number(x);
            } else if (typeof x === 'undefined') {
              return 0;
            } else {
              return isNaN(x) ? 0 : x;
            }
          };

          const _totalKeys = this.headers.map((x) => x.id);
          const _totals = this.lines.reduce((acc: any, next: any) => {
            if (this.groupByInventory && !this.groupByLotsWallets) {
              acc.inventory = 'TOTAL';
              acc.asset = '-';
            } else if (this.groupByInventory && this.groupByLotsWallets) {
              acc.original_inventory = 'TOTAL';
              acc.original_wallet = '-';
              acc.lotID = '-';
              acc.asset = '-';
            } else {
              acc.asset = 'TOTAL';
            }
            acc.current_price = '-';

            for (const key of _totalKeys) {
              if (
                key === 'asset' ||
                key === 'original_inventory' ||
                key === 'original_wallet' ||
                key === 'lotID' ||
                key === 'inventory' ||
                key === 'current_price'
              ) {
                continue;
              }

              let _key = key;

              // key mapping for correcting fields in headers
              if (key === 'st_gain_loss') {
                _key = next[key] ? 'st_gain_loss' : 'stGainLoss_fiat_decreases';
              }

              if (key === 'un_gain_loss') {
                _key = next[key] ? 'un_gain_loss' : 'unGainLoss_fiat_decreases';
              }

              if (key === 'lt_gain_loss') {
                _key = next[key] ? 'lt_gain_loss' : 'ltGainLoss_fiat_decreases';
              }

              if (key === 'fiat_decreases') {
                _key = next[key] ? 'fiat_decreases' : 'fmv_disposed_fiat_decreases';
              }
              if (key === 'unrealized_gain_loss') {
                if (!next.isTotal) {
                  this.unrealizedGainLoss += _myNum(next[key]);
                }
              }

              // first check if existing value is a string, if so convert to number
              acc[_key] = _myNum(acc[_key]);

              // add accumulated value to next value
              acc[_key] += _myNum(next[_key]);
            }

            return acc as DataTableLine;
          }, {} as DataTableLine);

          // add totals to the end of the lines
          this.lines.push({ ..._totals, isTotal: true });

          this.summary = summaryResp.data.summary;
        }
      } finally {
        this.isLoading = false;
        this.reportElapsedTime = this.getElapsedTime(reportStartTime);
      }
    }
  }

  public csvLoading = false;

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;

  downloadFile = downloadAuthorizedFile;

  async downloadReport() {
    if (this.view && this.view.id) {
      this.csvLoading = true;

      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const p1 = svc.getAdvancedCostBasisRollForward(
          this.orgId,
          this.view.id,
          this.startDate,
          this.endDate,
          this.groupByInventory,
          this.groupByLotsWallets && this.groupByInventory,
          this.groupByLotsWallets && this.groupByInventory,
          true,
          this.includeFmv,
          {
            withCredentials: true,
          }
        );

        const resp = await p1;

        if (resp.status === 200) {
          const exportIds = resp.data.exportIds;
          const downloadUrlPromises = [] as any[];
          exportIds?.forEach((x: any) => {
            downloadUrlPromises.push(
              axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
                withCredentials: true,
              })
            );
          });
          const downloadUrls = await Promise.all(downloadUrlPromises);
          downloadUrls.forEach((x: any) => {
            this.downloadFile(x.data);
          });
        }
      } finally {
        this.csvLoading = false;
      }
    }
  }
}
