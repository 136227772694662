import { render, staticRenderFns } from "./InventoryViewSpecID.vue?vue&type=template&id=6546dfaf&"
import script from "./InventoryViewSpecID.vue?vue&type=script&lang=ts&"
export * from "./InventoryViewSpecID.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports