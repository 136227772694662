


































































































































import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import ReportHeader from '@/components/inventory/ReportHeader.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';
import numberFormatter from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import {
  ApiSvcInventoryCounts,
  ApiSvcInventoryShortBalanceLine,
  ApiSvcInventoryView,
  InventoryApi,
} from '../../../../generated/api-svc';

@Component({
  components: {
    UiDatePicker,
    CreateInventoryView,
    UiButton,
    WalletListNew,
    UiDataTable,
    ReportHeader,
    UiTruncateText,
    UiCheckbox,
  },
})
export default class InventoryViewDashboard extends BaseVue {
  @Prop({ default: null })
  public readonly currentView!: ApiSvcInventoryView | null;

  public lines: ApiSvcInventoryShortBalanceLine[] = [];
  public counts: ApiSvcInventoryCounts | null = null;
  public asOf = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public numFormat = numberFormatter.format;

  public isLoading = false;

  public get csvHeaders() {
    const retVal = [];
    if (this.currentView?.impair) {
      retVal.push(
        ...[
          'asset',
          'qty',
          'costBasisAcquired',
          'costBasisRelieved',
          'impairmentExpense',
          'impairmentExpenseReversal',
          'costBasis',
          'carryingValue',
          'fairMarketValue',
          'unrealizedGainLoss',
        ]
      );
    } else {
      retVal.push(
        ...[
          'asset',
          'qty',
          'costBasisAcquired',
          'costBasisRelieved',
          'costBasis',
          'fairMarketValue',
          'unrealizedGainLoss',
        ]
      );
    }
    if (this.showInventory && this.groupByInventory) {
      retVal.push('inventory');
    }
    return retVal;
  }

  public groupByInventory = false;

  public onGroupByInventoryChange(val: boolean) {
    if (val) {
      this.loadData();
    }
  }

  public csvLabels = {
    asset: 'Asset',
    qty: 'Qty',
    inventory: 'Inventory',
    costBasisAcquired: 'Cost Basis (Acq)',
    costBasisRelieved: 'Cost Basis (Disp)',
    costBasis: 'Cost Basis',
    carryingValue: 'Carrying Value',
    fairMarketValue: 'Fair Market Value',
    unrealizedGainLoss: 'Unrealized Gain/Loss',
    impairmentExpense: 'Impairment',
    impairmentExpenseReversal: 'Impairment Reversal',
  };

  public get showInventory() {
    return (
      this.currentView?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-group-mapping' ||
      this.currentView?.inventoryConfig?.inventoryMappingRule?.type === 'inventory-per-wallet'
    );
  }

  public get headers() {
    if (this.currentView?.impair) {
      return [
        {
          id: 'inventory',
          label: 'Inventory',
          defaultVisibility: this.groupByInventory && this.showInventory,
        },
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
        },
        {
          id: 'qty',
          label: 'Qty',
          defaultVisibility: true,
        },
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis (Acq)',
          defaultVisibility: true,
        },
        {
          id: 'costBasisDisposed',
          label: 'Cost Basis (Disp)',
          defaultVisibility: true,
        },
        {
          id: 'impairmentExpense',
          label: 'Impairment',
          defaultVisibility: true,
        },
        {
          id: 'impairmentExpenseReversal',
          label: 'Impairment Reversal',
          defaultVisibility: true,
        },
        {
          id: 'costBasis',
          label: 'Cost Basis',
          defaultVisibility: true,
        },
        {
          id: 'carryingValue',
          label: 'Carrying Value',
          defaultVisibility: true,
        },
        {
          id: 'fairMarketValue',
          label: 'Fair Market Value [Rate]',
          defaultVisibility: true,
        },
        {
          id: 'unrealizedGainLoss',
          label: 'Unrealized Gain Loss',
          defaultVisibility: true,
        },
      ];
    } else {
      return [
        {
          id: 'asset',
          label: 'Asset',
          defaultVisibility: true,
        },
        {
          id: 'qty',
          label: 'Qty',
          defaultVisibility: true,
        },
        {
          id: 'inventory',
          label: 'Inventory',
          defaultVisibility: this.groupByInventory && this.showInventory,
          sortable: true,
        },
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis (Acq)',
          defaultVisibility: true,
        },
        {
          id: 'costBasisDisposed',
          label: 'Cost Basis (Disp)',
          defaultVisibility: true,
        },
        {
          id: 'costBasis',
          label: 'Cost Basis',
          defaultVisibility: true,
        },
        {
          id: 'fairMarketValue',
          label: 'Fair Market Value [Rate]',
          defaultVisibility: true,
        },
        {
          id: 'unrealizedGainLoss',
          label: 'Unrealized Gain Loss',
          defaultVisibility: true,
        },
      ];
    }
  }

  public viewActions(options: { status: string; asOf: string }) {
    this.$emit('viewActions', options);
  }

  public submittedAsOf = '';

  public loadData() {
    this.submittedAsOf = this.asOf;
    this.loadInventoryBalance();
  }

  private async loadInventoryBalance() {
    this.isLoading = true;
    try {
      if (this.currentView && this.currentView.id) {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const promise = svc.getViewBalance(this.orgId, this.currentView.id, this.submittedAsOf, this.groupByInventory, {
          withCredentials: true,
        });
        const resp = await promise;

        if (resp.status === 200) {
          this.counts = resp.data.counts;
          this.lines = resp.data.lines;

          const _myNum = (x: any) => {
            if (typeof x === 'string') {
              return isNaN(Number(x)) ? 0 : Number(x);
            } else if (!x) {
              return 0;
            } else {
              return isNaN(x) ? 0 : x;
            }
          };

          const _totalKeys = [...this.headers.map((x) => x.id), 'exchangeRate'];
          const _totals = this.lines.reduce((acc: any, next: any) => {
            acc.asset = 'TOTAL';
            acc.inventory = '-';
            for (const key of _totalKeys) {
              if (key === 'asset') {
                continue;
              }
              if (key === 'inventory') {
                continue;
              }

              let _key = key;

              if (key === 'costBasisDisposed') {
                _key = 'costBasisRelieved';
              }

              // first check if existing value is a string, if so convert to number
              acc[_key] = _myNum(acc[_key]);
              // add accumulated value to next value
              acc[_key] += _myNum(next[_key]);
            }
            return acc;
          }, {});
          // add totals to the end of the lines
          this.lines.push({ ..._totals, isTotal: true });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    this.loadData();
  }

  async downloadFile(link: string) {
    if (this.currentView && this.currentView.id && this.currentView.activeViewUpdateId) {
      const inventoryApi = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const downloadResp = await inventoryApi.getUpdateDownloadLinks(
        this.orgId,
        this.currentView.id,
        this.currentView.activeViewUpdateId,
        { withCredentials: true }
      );

      if (downloadResp.status === 200) {
        const downloadFrame = document.getElementById('downloadFrame') as HTMLIFrameElement;
        const l = (downloadResp.data._links as any)[link];
        if (l && downloadFrame) {
          downloadFrame.src = l.href;
        }
      } else {
        // TODO show error
      }
    }
  }
}
