





















































































































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { InventoryGroup } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { StrategyPriority } from '@/components/org/types';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { store } from '@/store';
import { InventoryActionTypes } from '@/store/modules/inventories/types';

import { baConfig } from '../../../config';
import {
  ApiSvcAssetValuationStrategies,
  ApiSvcAssetValuationStrategy,
  ApiSvcCreateInventoryViewRequest,
  ApiSvcGainLossSetupDTOTaxStrategyEnum,
  ApiSvcImpairmentMethodology,
  ApiSvcInventoryMappingRuleRequest,
  ApiSvcInventoryMappingRuleType,
  ApiSvcSpecificIdentificationCoinSpendingStrategy,
  ApiSvcSpecificIdentificationSelectionPreference,
  ApiSvcSpecificIdentificationSelectionStrategy,
  InventoryApi,
} from '../../../generated/api-svc';
import UiFormLabel from '../ui/UiFormLabel.vue';
import {
  allStrategies,
  AssetValuationStrategiesType,
  impairmentMethodologies,
  InventoryViewHelper,
  taxMethodsLabels,
} from './helpers/inventoryViewHelper';
import TaxStrategyPicker from './TaxStrategyPicker.vue';

@Component({
  components: {
    UiSelect,
    UiSelect2,
    UiDropdown,
    UiCheckbox,
    UiButton,
    UiTextEdit,
    UiLoading,
    UiFormLabel,
    TaxStrategyPicker,
  },
})
export default class CreateWalletNew extends BaseVue {
  public isCreatingInventoryView = false;

  public newInventoryName = '';
  public ignoreNFTs = false;
  public ignoreOrgWrappingTreatments = false;
  public costBasisCarryForwardAcquiredSide = false;
  public processAcquisitionsBeforeDisposals = true;
  public useOriginalAcquisitionDateForWliTransfers = true;
  public multipleInventories = false;
  public capitalizeFees = true;

  public isValidInput = false;
  public isValidating = false;
  public showDoneModal = false;
  public taxStrategy: ApiSvcGainLossSetupDTOTaxStrategyEnum = ApiSvcGainLossSetupDTOTaxStrategyEnum.Fifo;
  public impairmentMethodology = ApiSvcImpairmentMethodology.DailyLow;
  public inventoryMapping: ApiSvcInventoryMappingRuleType = ApiSvcInventoryMappingRuleType.PerWallet;
  public specIdStrategy: StrategyPriority[] = [];
  public defaultSelectedInventoryGroup: InventoryGroup = {
    orgId: '',
    walletIdsToInventoryIds: [],
    name: '',
    description: '',
  };

  public expandAdvanced = false;
  public engineVersion = 1.3;

  public matchDataSourceId = '';

  // we should only allow selecting the newest engine versions
  public engineVersions = [
    { label: 'v1.3', id: 1.3 },
    { label: 'v2.7', id: 2.7 },
  ];

  assetValuationStrategies: AssetValuationStrategiesType[] = [];
  public defaultStrategy = ApiSvcAssetValuationStrategies.GaapFairValue;

  public get strategies() {
    if (this.engineVersion === 1) {
      return [allStrategies[0], allStrategies[2]];
    } else if (this.checkFeatureFlag('mark-to-market-no-fmv-rollback')) {
      // Show all strategies when the feature flag is enabled
      return allStrategies.slice(0, 6);
    } else if (this.checkFeatureFlag('impairment')) {
      // Exclude the Mark To Market - No FMV Rollback strategy when 'impairment' is enabled
      return allStrategies.slice(0, 1).concat(allStrategies.slice(2, 6));
    } else {
      // Default case
      return [allStrategies[0], allStrategies[2], allStrategies[3], allStrategies[4]];
    }
  }

  public impairSelect = this.strategies[0];
  public selectedInventoryGroup = this.defaultSelectedInventoryGroup;

  public get impair() {
    return this.impairSelect !== allStrategies[0];
  }

  public get ifrsImpair() {
    return (
      (this.impairSelect === allStrategies[1] ||
        this.impairSelect === allStrategies[2] ||
        this.impairSelect === allStrategies[3] ||
        this.impairSelect === allStrategies[4] ||
        this.impairSelect === allStrategies[5]) &&
      this.engineVersion >= 2
    );
  }

  public get defaultValuationStrategy() {
    let strategy: ApiSvcAssetValuationStrategies | null = null;
    switch (this.impairSelect) {
      case allStrategies[0]:
        strategy = null;
        break;
      case allStrategies[1]:
        strategy = ApiSvcAssetValuationStrategies.MarkToMarket;
        break;
      case allStrategies[2]:
        strategy = ApiSvcAssetValuationStrategies.Impairment;
        break;
      case allStrategies[3]:
        strategy = ApiSvcAssetValuationStrategies.ImpairmentReversalNonRevalued;
        break;
      case allStrategies[4]:
        strategy = ApiSvcAssetValuationStrategies.ImpairmentReversalRevalued;
        break;
      case allStrategies[5]:
        strategy = ApiSvcAssetValuationStrategies.GaapFairValue;
        break;
    }
    return strategy;
  }

  get taxMethods() {
    const selectedSpecId = this.checkFeatureFlag('selected-spec-id');
    return taxMethodsLabels(selectedSpecId);
  }

  public impairmentMethodologies = impairmentMethodologies;

  public inventoryMappings: {
    [key: string]: ApiSvcInventoryMappingRuleType;
  } = {
    'Per Wallet': ApiSvcInventoryMappingRuleType.PerWallet,
  };

  public get validateInventoryName() {
    return this.newInventoryName.length >= 3;
  }

  public get valuationStrategies() {
    if (this.checkFeatureFlag('mark-to-market-no-fmv-rollback')) {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('mark-to-market-no-fmv-rollback');
    } else if (this.checkFeatureFlag('impairment')) {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('impairment');
    } else {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('default');
    }
  }

  public handleSelectInventoryGroup(value: InventoryGroup) {
    this.selectedInventoryGroup = value;
  }

  public changeVersion(value: number) {
    if (value === 1) {
      this.multipleInventories = false;
      if (!this.strategies.includes(this.impairSelect)) {
        this.impairSelect = this.strategies[0];
      }
    }
  }

  get isValid() {
    if (this.multipleInventories && this.inventoryMapping === ApiSvcInventoryMappingRuleType.GroupMapping) {
      if (this.selectedInventoryGroup.id === undefined) return false;
    }
    if (this.impair && !this.defaultValuationStrategy) return false;
    if (this.impair && this.assetValuationStrategies.some((x) => !x.coin || !x.strategy)) return false;
    return this.isValidInput;
  }

  public getIsValid() {
    if (this.multipleInventories && this.inventoryMapping === ApiSvcInventoryMappingRuleType.GroupMapping) {
      if (this.selectedInventoryGroup.id === undefined) return false;
    }
    return this.isValidInput;
  }

  public async validateTickers() {
    // Check if the tickers are valid
    const resp = await new InventoryViewHelper().validateTickers(this.assetValuationStrategies);
    if (resp && !resp.status) {
      this.showErrorSnackbar(resp.msg);
      return false;
    }

    return true;
  }

  public async createInventoryView() {
    this.isCreatingInventoryView = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const taxStrategy = this.taxStrategy;
      const specIdStrategy = this.mapSpendingStrategy();

      let inventoryMappingRule: ApiSvcInventoryMappingRuleRequest | undefined;
      if (this.multipleInventories) {
        inventoryMappingRule = {
          type: this.inventoryMapping,
        };
        if (this.inventoryMapping === this.inventoryMappings['Inventory Groups'] && this.selectedInventoryGroup.id)
          inventoryMappingRule.inventoryGroupId = this.selectedInventoryGroup.id;
      }
      let defaultValuationStrategy: ApiSvcAssetValuationStrategies | undefined;
      if (this.defaultValuationStrategy) {
        defaultValuationStrategy = this.defaultValuationStrategy;
      }
      let assetValuationStrategies: ApiSvcAssetValuationStrategy[] | undefined;
      if (this.assetValuationStrategies?.length > 0 && this.engineVersion >= 2) {
        if (!(await this.validateTickers())) {
          return;
        }
        assetValuationStrategies = this.assetValuationStrategies.map((x) => ({
          ticker: x.coin,
          assetValuationStrategy: x.strategy,
        }));
      }
      const c: ApiSvcCreateInventoryViewRequest = {
        name: this.newInventoryName,
        impair: this.impair,
        ignoreNFTs: this.ignoreNFTs,
        ignoreOrgWrappingTreatments: this.ignoreOrgWrappingTreatments,
        strategy: {
          taxStrategy,
          specIdStrategy,
          matchDataSourceId: this.matchDataSourceId,
        },
        config: {
          capitalizeTradingFees: this.capitalizeFees,
          impairmentMethodology: this.impair ? this.impairmentMethodology : undefined,
          inventoryMappingRule,
          defaultValuationStrategy: this.impair ? defaultValuationStrategy : undefined,
          assetValuationStrategies,
          engineVersionOverride: this.engineVersion,
          costBasisCarryForwardAcquiredSide: this.costBasisCarryForwardAcquiredSide,
          processAcquisitionsBeforeDisposals: this.processAcquisitionsBeforeDisposals,
          useOriginalAcquisitionDateForWliTransfers: this.useOriginalAcquisitionDateForWliTransfers,
        },
      };

      const resp = await svc.createView(orgId, c, {
        withCredentials: true,
      });

      this.$emit('refresh-views');
      this.$emit('back');
    } finally {
      this.isCreatingInventoryView = false;
    }
  }

  public mapSpendingStrategy(): ApiSvcSpecificIdentificationSelectionStrategy[] {
    return this.specIdStrategy.map((s) => {
      let spending: ApiSvcSpecificIdentificationCoinSpendingStrategy;
      let preference: ApiSvcSpecificIdentificationSelectionPreference;
      switch (s.spendingStrategy) {
        case 'ShortTermGain':
          spending = ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_1;
          break;
        case 'LongTermGain':
          spending = ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_3;
          break;
        case 'ShortTermLoss':
          spending = ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_2;
          break;
        default:
          spending = ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_4;
          break;
      }
      switch (s.selectionPreference) {
        case 'Minimize':
          preference = ApiSvcSpecificIdentificationSelectionPreference.NUMBER_1;
          break;
        default:
          preference = ApiSvcSpecificIdentificationSelectionPreference.NUMBER_2;
          break;
      }
      return {
        spendingStrategy: spending,
        selectionPreference: preference,
      };
    });
  }

  public onSpendingStrategyChange(value: StrategyPriority[]) {
    this.specIdStrategy = value;
  }

  public validate(valid: boolean) {
    this.isValidInput = true;
  }

  // == hooks ==
  async mounted() {
    await store.dispatch('inventories/' + InventoryActionTypes.GET_INVENTORIES, { orgId: store.state.currentOrg.id });
    if (this.checkFeatureFlag('inventory-management')) {
      this.inventoryMappings = {
        'Per Wallet': ApiSvcInventoryMappingRuleType.PerWallet,
        'Inventory Groups': ApiSvcInventoryMappingRuleType.GroupMapping,
      };
    }
    if (this.checkFeatureFlag('sqlite')) {
      this.engineVersions.push({ label: 'v3.0', id: 3.0 });
    }
  }

  // == getters ==
  get inventoryGroups() {
    return store.getters['inventories/INVENTORY_GROUPS'];
  }

  @Watch('inventoryMapping')
  switchMultipleInventoryStrategy() {
    this.selectedInventoryGroup = this.defaultSelectedInventoryGroup;
  }

  @Watch('multipleInventories')
  switchMultiple() {
    this.inventoryMapping = ApiSvcInventoryMappingRuleType.PerWallet;
  }
}
