






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FirstTimeListItem extends Vue {
  @Prop({ default: '' })
  public readonly title!: string;

  public expand = false;

  public click() {
    this.expand = !this.expand;
    this.$emit('click', this.expand);
  }
}
